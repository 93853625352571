<template>
  <div style="padding-bottom:56px;">
    <template v-if="detail.id">
      <template v-if="detail.loading">
        <component :is="myComponent" :hd="detail" v-if="myComponent" ref="activity" :gList="gList" :goodList="goodList"/>
        
        <div class="top_r_action">
          <a @click="complaint" v-if="snapshotuser==0">投诉</a>
          <a @click="()=>{this.shopModal=true}">商家</a>
          <a @click="()=>{this.kefuModal=true}" v-if="kefuInfo.id">客服</a>
        </div>
        
        <a href="javascript:void(0);" :class="['audio-c',audioPlaying?'play':'off']" v-if="detail.is_music" @click.stop="startPlayOrPause"><audio :src="detail.music" autoplay="autoplay" loop ref="audio" @pause="audioPlaying=false" @play="audioPlaying=true"></audio></a>
        
        <div class="bottom_r_action" v-if="detail.state==1 && snapshotuser==0">
          <van-button color="#cc0000" @click="navigateTo(2)">我的奖励</van-button>
          <van-button type="primary" @click="navigateTo(1)" v-if="detail.hd_type==1">中奖记录</van-button>
        </div>
        
        <div class="footer">
          <p>访问量：{{detail.amount}}</p>
          <p v-if="detail.is_copyright">{{copyright}}</p>
        </div>
        
        <van-popup value position="bottom" :overlay="false" :lock-scroll="false" class="footerAction" v-if="detail.state==1 && snapshotuser==0">
          <van-row type="flex" justify="center">
            <van-col span="12" v-if="detail.poster != '' && userInfo != null"><van-button type="info" block class="btn1" @click="GeneratePosters">生成海报图</van-button></van-col>
            <van-col span="12" v-if="goodList.length>0"><van-button type="warning" block class="btn2" @click="goOrder">购买记录</van-button></van-col>
          </van-row>
        </van-popup>
      </template>
      <div v-else class="font-color-gray font-size-20 t-c" style="padding-top:100px;">活动暂停中，请等待恢复</div>

    </template>
    <template v-if="snapshotuser==0">
      <van-popup v-model="detail.is_adv"  :style="{width:'100%'}" class="openAdv">
        <van-count-down :time="detail.adv_time*1000" @finish="()=>{this.detail.is_adv=false}" class="time">
          <template #default="timeData">
            <span class="block">倒计时：{{ timeData.seconds }} 秒</span>
          </template>
        </van-count-down>
        <van-image width="100%" :src="detail.adv"/>
      </van-popup>
      
      <van-popup v-model="shopModal" :style="popStype" closeable>
        <shopInfo :data="detail.shopInfo"/>
      </van-popup>
    
      <van-popup v-model="kefuModal" :style="popStype">
        <div style="padding:20px;">
          <p><van-image width="100%" :src="kefuInfo.qrcode"/></p>
          <p class="t-c font-color-gray">长按二维码，添加客服</p>
        </div>
      </van-popup>
      
      <van-popup v-model="posterModal" :style="popStype">
        <div class="GeneratePoster">
          <div class="p_box" ref="poster" v-if="downimgurl==''">
            <img :src="detail.poster" width="100%" class="bgimg"/>
            <div class="qr">
              <div id="qrcode"></div>
              <img :src="userInfo.avatarUrl" width="100%" class="headimg"/>
            </div>
          
          </div>
          <div class="posterimg" v-else><img :src="downimgurl"/></div>
          <!--<div class="saveimg"><van-button plain type="info" size="small" @click="downloadCanvasIamge">保存至相册</van-button></div>-->
        </div>
      </van-popup>
    
      <global :data="shareData" :hdid="hdid" v-if="detail.id"/>
    </template>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import html2canvas from 'html2canvas';
  import global from './components/global'
  import shopInfo from './components/shopInfo'
  import {gethdcomponents} from '@/utils/util'
  import {GetHdInfo, GetKefuInfo, GetgoodList} from './services/services'
  import {mapGetters, mapMutations} from "vuex";
export default {
  name:'index',
  components: {shopInfo,global},
  data() {
    return {
      hdid:undefined,
      myComponent:null,
      goodList:[],//秒杀商品
      gList:[],//活动商品
      detail:{},
      share_url:'',
      copyright:process.env.VUE_APP_COPYRIGHT,
      shareData:{},
      shopModal:false,
      audioPlaying:false,
      posterModal:false,
      kefuModal:false,
      downimgurl:'',
      hduinfo:{},
      kefuInfo:{},
      popStype:{width:'92%'}
    }
  },
  computed:{
    ...mapGetters(['snapshotuser','userInfo','hduserInfo']),
  },
  activated() {
    this.setBgColor()
  },
  deactivated() {
    document.body.removeAttribute('style')
  },
  created(){
    this.hdid=parseInt(this.$route.params.hdid)
  },
  mounted() {
    this.findDetail()
    this.getKefu()
  },
  methods: {
    ...mapMutations(["sethdInfo"]),
    setBgColor(){
      if(this.detail.bgcolor!='' && this.detail.bgcolor!=null){
        document.querySelector('body').setAttribute('style', 'background-color:'+this.detail.bgcolor)
      }
    },
    findDetail(){
      GetHdInfo({hdid:this.hdid}).then(result => {
        if(result.data.code==1){
          this.detail=result.data.data
          this.detail.is_adv=(this.detail.is_adv && this.detail.loading)?true:false
          if(!this.detail.loading){
            return false
          }
          this.setBgColor()
          
          this.findgoodList()
          if(this.detail.hd_type==1){
            this.findgList()
          }
          this.sethdInfo(this.detail)
          const {fx_desc,fx_img,fx_title,url} = this.detail
          this.share_url=url+this.$route.path
          this.shareData={
            title:fx_title,
            link:this.share_url,
            imgurl:fx_img,
            desc:fx_desc,
          }
          document.title = this.detail.title
          if(this.detail.is_music && (this.detail.music != '' && this.detail.music != null)){
            this.audioAutoPlay()
          }
          
          let route = gethdcomponents(this.detail.hd_type)
          this.myComponent = () => import(`./${route}/index.vue`)
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    getKefu(){
      if(this.hduserInfo==null){
        setTimeout(()=>{
          this.getKefu()
        },200)
        return
      }

      if(this.hduserInfo.is_kefu==1&&this.hduserInfo.qrcode!=''&&this.hduserInfo.qrcode!=null){
        const {id,uid,qrcode} = this.hduserInfo
        this.kefuInfo={id,uid,qrcode}
        
      }else{
        const {kefu_id} = this.$route.query
        let _kf=kefu_id==undefined?this.hduserInfo.parent_id:parseInt(kefu_id)
        if(_kf>0){
          GetKefuInfo({hdid:this.hdid,uid:_kf}).then(result => {
            if(result.data.code==1){
              let data=result.data.data
              if(data.is_kefu==1 && data.qrcode!='' && data.qrcode!=null){
                this.kefuInfo=data
              }
            }else{
              this.$toast(result.data.msg)
            }
          })
        }
      }
    },
    findgList(){
      GetgoodList({hdid:this.hdid,type:2}).then(result => {
        if(result.data.code==1){
          this.gList=result.data.data
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    findgoodList(){
      GetgoodList({hdid:this.hdid,type:1}).then(result => {
        if(result.data.code==1){
          this.goodList=result.data.data
        }else{
          this.$toast(result.data.msg)
        }
      })
    },
    startPlayOrPause () {
      if(this.audioPlaying){
        this.pause()
      }else{
        this.play()
      }
    },
    play(){
      this.$refs.audio.play()
    },
    pause () {
      this.$refs.audio.pause()
    },
    audioAutoPlay(){
      setTimeout(() => {
        this.play()
        document.addEventListener("WeixinJSBridgeReady",()=> {
          this.play()
        },false)
        document.addEventListener("touchstart",()=> {
          this.play()
        }, false);
      }, 1000);
    },
    complaint(){
      this.$router.push({path:'/wx/complaint?url='+this.detail.url+this.$route.path})
    },
    GeneratePosters(){
      if(this.detail.state==0){
        this.$toast('活动即将开始')
        return false
      }else if(this.detail.state==2){
        this.$toast('活动已结束')
        return false
      }
      this.posterModal=true

      let url = this.share_url+((this.userInfo==null||this.userInfo==undefined)?'':('?utocode='+this.userInfo.id))+((this.hduserInfo.qrcode!=''&&this.hduserInfo.is_kefu==1)?('&kefu_id='+this.userInfo.id):'')
      console.log(url)
      this.$nextTick(() => {
        new QRCode('qrcode',{
          width:200, // 设置宽度，单位像素
          height:200, // 设置高度，单位像素
          colorLight: "#ffffff",
          text:url
        })
        html2canvas(this.$refs.poster,{
          allowTaint:false,
          useCORS:true
        }).then((canvas)=> {
          this.downimgurl = canvas.toDataURL('image/png')
        });
      })
    },
    goOrder(){
      this.$router.push({path:'/wx/order/'+this.detail.id})
    },
    navigateTo(type){
      switch (type) {
        case 1:
          this.$router.push({path:'/wx/prize/'+this.detail.id})
          break;
        case 2:
          this.$router.push({path:'/wx/commission/'+this.detail.id})
          break;
      }
    }
  }
}
</script>
